import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'semantic-ui-react';

import { ClientDetail } from 'pages';
import { pageUrl } from 'config/routes';

export function TrackedBackIDsCell({ trackedBackIDs }) {
  return (
    <Table.Cell>
      {trackedBackIDs.map((clientId, idx) => (
        <Fragment id={clientId}>
          <Link
            target="_blank"
            to={pageUrl(ClientDetail, {
              clientId
            })}>
            {clientId}
          </Link>
          {idx !== trackedBackIDs.length - 1 && ', '}
        </Fragment>
      ))}
    </Table.Cell>
  );
}
