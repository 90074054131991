import React from 'react';

import { Table } from 'semantic-ui-react';

import { CorporateMasterAccount } from './components';

class CorporateMasterAccountsTable extends React.Component {
  render() {
    const { corporateProfiles, client, fetchClient } = this.props;

    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell data-testid="linked-c-accounts-table-id-col">
              ID
            </Table.HeaderCell>
            <Table.HeaderCell data-testid="linked-c-accounts-table-first-name-col">
              First Name
            </Table.HeaderCell>
            <Table.HeaderCell data-testid="linked-c-accounts-table-last-name-col">
              Last Name
            </Table.HeaderCell>
            <Table.HeaderCell data-testid="linked-c-accounts-table-job-title-col">
              Job Title
            </Table.HeaderCell>
            <Table.HeaderCell data-testid="linked-c-accounts-table-email-col">
              E-mail
            </Table.HeaderCell>
            <Table.HeaderCell data-testid="linked-c-accounts-table-city-col">
              City
            </Table.HeaderCell>
            <Table.HeaderCell data-testid="linked-c-accounts-table-country-col">
              Country
            </Table.HeaderCell>
            <Table.HeaderCell data-testid="linked-c-accounts-table-subscription-status-col">
              Subscription status
            </Table.HeaderCell>
            <Table.HeaderCell data-testid="linked-c-accounts-table-subscription-expiry-date-col">
              Subscription expiry date
            </Table.HeaderCell>
            <Table.HeaderCell data-testid="linked-c-accounts-table-actions-col">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {corporateProfiles.map(corporateProfile => (
            <CorporateMasterAccount
              key={corporateProfile.id}
              corporateProfile={corporateProfile}
              client={client}
              fetchClient={fetchClient}
            />
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default CorporateMasterAccountsTable;
