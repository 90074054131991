import React from 'react';
import _ from 'lodash';

import { Formik } from 'formik';

import { Form, Segment, Statistic, Icon, Divider } from 'semantic-ui-react';

import { buildInitialValuesFromFilters } from 'utils/filters';

import {
  Field,
  FormRow,
  InputField,
  AdminSelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  ClientProfileTypeSelect,
  DateField,
  FilterButtons,
  EmailTypeSelect,
  RegistrationTypeSelect,
  CountrySelect,
  RegionSelect,
  IndustrySelect,
  BusinessActivitySelect,
  YesNoSelect,
  MarketDataReportRelatedProductSelect,
  ProductStatusSelect,
  UpcomingEventSelect,
  PastEventSelect,
  CommoditySelect,
  PrioritySelect,
  EventStatusSelect,
  SubIndustrySelect,
  UnsubscribeMethodSelect
} from 'components';

import { FILTERS } from './utils';

import styles from './styles.module.css';

class ClientFilters extends React.Component {
  state = {
    advanced: false
  };

  toggleAdvanced = () => {
    this.setState(prevState => {
      return {
        advanced: !prevState.advanced
      };
    });
  };

  submitSearch = values => {
    this.props.filterBy(values);
  };

  resetSearch = resetForm => {
    const { clearFilters } = this.props;

    resetForm();
    clearFilters();
  };

  render() {
    const { filters, count } = this.props;

    let extraFilters = this.props.extraFilters || [];

    const initialValues = buildInitialValuesFromFilters(filters, [
      ...FILTERS,
      ...extraFilters
    ]);

    return (
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.submitSearch}>
          {({ values, handleSubmit, resetForm, setFieldValue }) => (
            <Form>
              <FormRow>
                {extraFilters.map(filter => (
                  <Field
                    key={filter.name}
                    name={filter.name}
                    label={filter.label}
                    component={filter.component}
                    {...filter.rest}
                  />
                ))}
              </FormRow>
              <FormRow className={styles.Grid}>
                <Field
                  width={8}
                  name="search"
                  placeholder="Search ..."
                  component={InputField}
                  label="General search"
                />
                <Field
                  width={5}
                  name="id"
                  component={InputField}
                  label="Client ID"
                />
                <Field
                  width={5}
                  name="has_admin_updates"
                  component={YesNoSelect}
                  label="Has admin updates"
                />
              </FormRow>
              <FormRow>
                <Field
                  width={4}
                  name="email"
                  component={InputField}
                  label="Email address"
                />
                <Field
                  width={3}
                  name="first_name"
                  component={InputField}
                  label="First Name"
                />
                <Field
                  width={3}
                  name="last_name"
                  component={InputField}
                  label="Last Name"
                />
                <Field
                  width={5}
                  name="job_title"
                  component={InputField}
                  label="Job Title"
                />
                <Field
                  width={5}
                  name="company_name"
                  component={InputField}
                  label="Company Name"
                />
              </FormRow>
              <FormRow>
                <Field
                  width={2}
                  name="is_active"
                  component={YesNoSelect}
                  label="User Status"
                  yesLabel="Active"
                  noLabel="Inactive"
                />
                <Field
                  width={2}
                  name="cm"
                  component={ClientProfileTypeSelect}
                  label="CM filter"
                />
                <AdminSelect
                  width={4}
                  name="account_manager"
                  label="Account manager"
                />
                <YesNoSelect
                  width={3}
                  name="unsubscribed"
                  label="Un/Subscribed"
                  yesLabel="Unsubscribed only"
                  noLabel="Subscribed only"
                />
                <PrioritySelect
                  width={3}
                  name="priority"
                  label="Priority"
                  isMulti={true}
                />
              </FormRow>
              <FormRow>
                <SubscriptionSelect
                  width={4}
                  name="subscription"
                  label="Subscription"
                  isMulti={true}
                />
                <SubscriptionStatusSelect
                  width={4}
                  name="subscription_status"
                  label="Subscription status"
                  isMulti={true}
                />
                <Field
                  name="unsubscribed_at"
                  label="Unsubscribed At"
                  width={4}
                  component={DateField}
                />
                <Field
                  name="unsubscribed_method"
                  label="Unsubscribed Method"
                  width={4}
                  component={UnsubscribeMethodSelect}
                />
              </FormRow>

              <FormRow>
                <Field
                  width={3}
                  name="subscription_starts_from"
                  component={DateField}
                  label="Subscription starts from"
                />
                <Field
                  width={3}
                  name="subscription_starts_to"
                  component={DateField}
                  label="Subscription starts to"
                />
                <Field
                  width={3}
                  name="subscription_expires_from"
                  component={DateField}
                  label="Subscription expires from"
                />
                <Field
                  width={3}
                  name="subscription_expires_to"
                  component={DateField}
                  label="Subscription expires to"
                />
                <Field
                  width={2}
                  name="uses_mobile_app"
                  component={YesNoSelect}
                  label="Uses mobile app"
                />
                <Field
                  width={3}
                  name="has_my_snapshot_prices"
                  component={YesNoSelect}
                  label="Has my snapshot prices"
                />
              </FormRow>

              <Form.Field flexEnd className={styles.advancedLabelRow}>
                <span
                  className={styles.showAdvancedLabel}
                  onClick={this.toggleAdvanced}>
                  {!this.state.advanced ? 'Open' : 'Close'} advanced filters
                  <Icon
                    name={!this.state.advanced ? 'angle down' : 'angle up'}
                  />
                </span>
              </Form.Field>

              {this.state.advanced && (
                <>
                  <Divider />
                  <FormRow>
                    <Field
                      name="upcoming_event"
                      component={UpcomingEventSelect}
                      label="Upcoming event"
                      isMulti={true}
                    />
                    <Field
                      name="past_event"
                      component={PastEventSelect}
                      label="Past event"
                      isMulti={true}
                    />
                    <EventStatusSelect
                      name="event_status"
                      label="Event status"
                      isMulti={true}
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      width={5}
                      name="industry"
                      component={IndustrySelect}
                      label="Industry"
                      isMulti={true}
                      onChange={e => setFieldValue('sub_industry', [])}
                    />
                    <Field
                      width={5}
                      name="sub_industry"
                      component={SubIndustrySelect}
                      industry={values.industry}
                      label="Sub-Industry"
                      isMulti={true}
                      disabled={_.isEmpty(values.industry)}
                    />

                    <Field
                      width={5}
                      name="business_activity"
                      component={BusinessActivitySelect}
                      label="Business activity"
                      isMulti={true}
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      width={5}
                      name="countries"
                      component={CountrySelect}
                      label="Country"
                      isMulti={true}
                    />
                    <Field
                      width={4}
                      name="is_in_sanctioned_country"
                      component={YesNoSelect}
                      label="Not/Sanctioned countries"
                      yesLabel="Sanctioned only"
                      noLabel="Not sanctioned only"
                    />
                    <Field
                      width={2}
                      name="has_country"
                      component={YesNoSelect}
                      label="Has country"
                    />
                    <Field
                      width={5}
                      name="regions"
                      component={RegionSelect}
                      label="Region"
                      isMulti={true}
                    />
                    <Field
                      width={5}
                      name="commodities"
                      component={CommoditySelect}
                      label="Commodity"
                      isMulti={true}
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      width={2}
                      name="interested_in_events"
                      component={YesNoSelect}
                      label="Interested in events"
                    />
                    <Field
                      width={2}
                      name="interested_in_news"
                      component={YesNoSelect}
                      label="Interested in news"
                    />
                    <Field
                      width={2}
                      name="interested_in_reports"
                      component={YesNoSelect}
                      label="Interested in reports"
                    />
                  </FormRow>
                  <FormRow></FormRow>
                  <FormRow>
                    <Field
                      width={2}
                      name="registration_type"
                      component={RegistrationTypeSelect}
                      label="Registration type"
                    />
                    <Field
                      width={2}
                      name="email_type"
                      component={EmailTypeSelect}
                      label="Email type"
                    />
                    <Field
                      width={3}
                      name="receive_steel_newsletter_in_pdf"
                      component={YesNoSelect}
                      label="Receives Steel Newsletter In PDF"
                    />
                  </FormRow>

                  <FormRow>
                    <Field
                      width={4}
                      name="address"
                      component={InputField}
                      label="Address"
                    />
                    <Field
                      width={3}
                      name="state_or_province"
                      component={InputField}
                      label="State or province"
                    />
                    <Field
                      width={3}
                      name="phone"
                      component={InputField}
                      label="Phone"
                    />
                    <Field
                      width={4}
                      name="fax"
                      component={InputField}
                      label="Fax"
                    />
                  </FormRow>

                  <FormRow>
                    <Field
                      width={4}
                      name="city"
                      component={InputField}
                      label="City (town)"
                    />
                    <Field
                      width={3}
                      name="postcode"
                      component={InputField}
                      label="Postcode"
                    />
                    <Field
                      width={3}
                      name="mobile"
                      component={InputField}
                      label="Mobile"
                    />
                    <Field
                      width={4}
                      name="website"
                      component={InputField}
                      label="Website"
                    />
                  </FormRow>

                  <FormRow>
                    <Field
                      width={5}
                      name="product"
                      component={MarketDataReportRelatedProductSelect}
                      label="Product"
                    />
                    <Field
                      width={4}
                      name="product_status"
                      component={ProductStatusSelect}
                      label="Product status"
                    />
                  </FormRow>
                  <Divider />
                  <FormRow flexEnd>
                    <div
                      className={styles.showAdvancedLabel}
                      onClick={this.toggleAdvanced}>
                      Close advanced filters
                      <Icon name="angle up" />
                    </div>
                  </FormRow>
                </>
              )}

              <div className={styles.footerRow}>
                <Statistic.Group size="tiny">
                  <Statistic color="green">
                    <Statistic.Value>{count}</Statistic.Value>
                    <Statistic.Label>Clients</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
                <FormRow>
                  <FilterButtons
                    searchAction={handleSubmit}
                    resetAction={() => this.resetSearch(resetForm)}
                  />
                </FormRow>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}
export default ClientFilters;
