import _ from 'lodash';
import { transformValuesForMultiselect } from 'utils/filters';
import { coerceStringToBoolean } from 'utils';
import {
  InputField,
  YesNoSelect,
  ClientProfileTypeSelect,
  AdminSelect,
  CorporateMasterAccountsSelect,
  PrioritySelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  UpcomingEventSelect,
  PastEventSelect,
  EventStatusSelect,
  IndustrySelect,
  SubIndustrySelect,
  BusinessActivitySelect,
  CountrySelect,
  RegionSelect,
  CommoditySelect,
  DateField,
  RegistrationTypeSelect,
  EmailTypeSelect,
  MarketDataReportRelatedProductSelect,
  ProductStatusSelect,
  UniSelect,
  ClientTagsSelectField
} from 'components';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'General search',
      placeholder: 'Search...',
      width: 8
    }
  ],
  [
    {
      name: 'has_company_name',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has Company name',
      width: 2
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name',
      width: 3
    }
  ],
  [
    {
      name: 'is_active',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'User Status',
      props: () => ({
        yesLabel: 'Active',
        noLabel: 'Inactive'
      }),
      width: 2
    },
    {
      name: 'cm',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: ClientProfileTypeSelect,
      label: 'CM filter',
      width: 2
    },
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account manager',
      width: 4
    },
    {
      name: 'priority',
      emptyValue: null,
      fieldComponent: PrioritySelect,
      label: 'Priority',
      width: 3,
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'c_accounts_for_cm',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: CorporateMasterAccountsSelect,
      label: 'Show children for CM:',
      width: 5,
      props: () => ({
        isMulti: true
      })
    }
  ],
  [
    {
      name: 'subscription',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionSelect,
      label: 'Subscription',
      props: () => ({
        isMulti: true
      }),
      width: 6
    },
    {
      name: 'subscription_status',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription status',
      props: () => ({
        isMulti: true
      }),
      width: 6
    },
    {
      name: 'unsubscribed',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Un/Subscribed',
      props: () => ({
        yesLabel: 'Unsubscribed only',
        noLabel: 'Subscribed only'
      }),
      width: 3
    }
  ],
  [
    {
      name: 'upcoming_event',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UpcomingEventSelect,
      label: 'Upcoming event',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'past_event',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: PastEventSelect,
      label: 'Past event',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'event_status',
      emptyValue: null,
      fieldComponent: EventStatusSelect,
      label: 'Event status',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'attended_event',
      emptyValue: null,
      fieldComponent: YesNoSelect,
      label: 'Attended Event'
    }
  ],
  [
    {
      name: 'industry',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: IndustrySelect,
      label: 'Industry',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'sub_industry',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubIndustrySelect,
      label: 'Sub-Industry',
      props: ({ values }) => ({
        isMulti: true,
        disabled: _.isEmpty(values.industry),
        industry: values.industry
      }),
      width: 5
    },
    {
      name: 'business_activity',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: BusinessActivitySelect,
      label: 'Business activity',
      props: () => ({
        isMulti: true
      }),
      width: 5
    }
  ],
  [
    {
      name: 'countries',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: CountrySelect,
      label: 'Country',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'is_in_sanctioned_country',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Not/Sanctioned countries',
      width: 4,
      props: () => ({
        yesLabel: 'Sanctioned only',
        noLabel: 'Not sanctioned only'
      })
    },
    {
      name: 'has_country',
      emptyValue: null,
      transform: coerceStringToBoolean,
      label: 'Has country',
      width: 2,
      fieldComponent: YesNoSelect
    },
    {
      name: 'states',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'State',
      props: () => ({
        isMulti: true,
        source: 'salesTerritoryStates'
      }),
      width: 5
    },
    {
      name: 'regions',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: RegionSelect,
      label: 'Region',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'commodities',
      emptyValue: [],
      fieldComponent: CommoditySelect,
      label: 'Commodity',
      props: () => ({
        isMulti: true
      }),
      width: 5
    }
  ],
  [
    {
      name: 'territory_manager',
      emptyValue: null,
      fieldComponent: UniSelect,
      label: 'Sales Territory Manager',
      width: 5,
      props: ({ setFieldValue }) => ({
        source: 'salesTerritoryStaffProfiles',
        onChange: event => {
          const extraData = _.get(event, 'target.extraData');
          const salesTerritories = _.get(
            event,
            'target.extraData.sales_territories'
          );

          let states = [];
          for (let saleTerritory of salesTerritories) {
            states.push(...saleTerritory.states);
          }

          if (!_.isNil(event) && !_.isNil(extraData)) {
            if (!_.isNil(salesTerritories)) {
              setFieldValue(
                'countries',
                salesTerritories.map(country => country.id)
              );
            }
            if (!_.isNil(states)) {
              setFieldValue(
                'states',
                states.map(state => state.id)
              );
            }
          }
        }
      })
    }
  ],
  [
    {
      name: 'interested_in_events',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Interested in events',
      width: 2
    },
    {
      name: 'interested_in_news',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Interested in news',
      width: 2
    },
    {
      name: 'interested_in_reports',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Interested in reports',
      width: 2
    }
  ],
  [
    {
      name: 'tags',
      emptyValue: [],
      component: ClientTagsSelectField,
      label: 'Contains Tags',
      props: () => ({ isCreatable: false }),
      width: 4
    }
  ]
];

export const ADVANCED_FILTERS_LAYOUT = [
  [
    {
      name: 'job_title',
      emptyValue: '',
      component: InputField,
      label: 'Job Title',
      width: 4
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name',
      width: 4
    },
    {
      name: 'id',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      width: 4
    }
  ],
  [
    {
      name: 'subscription_starts_from',
      emptyValue: null,
      component: DateField,
      label: 'Subscription starts from',
      width: 3
    },
    {
      name: 'subscription_starts_to',
      emptyValue: null,
      component: DateField,
      label: 'Subscription starts to',
      width: 3
    },
    {
      name: 'subscription_expires_from',
      emptyValue: null,
      component: DateField,
      label: 'Subscription expires from',
      width: 3
    },
    {
      name: 'subscription_expires_to',
      emptyValue: null,
      component: DateField,
      label: 'Subscription expires to',
      width: 3
    }
  ],
  [
    {
      name: 'email',
      emptyValue: '',
      component: InputField,
      label: 'Email address',
      width: 4
    },
    {
      name: 'first_name',
      emptyValue: '',
      component: InputField,
      label: 'First name',
      width: 3
    },
    {
      name: 'last_name',
      emptyValue: '',
      component: InputField,
      label: 'Last name',
      width: 3
    },
    {
      name: 'registration_type',
      emptyValue: '',
      fieldComponent: RegistrationTypeSelect,
      label: 'Registration type',
      width: 3
    }
  ],
  [
    {
      name: 'email_type',
      emptyValue: '',
      fieldComponent: EmailTypeSelect,
      label: 'Email type',
      width: 3
    },
    {
      name: 'receive_steel_newsletter_in_pdf',
      emptyValue: null,
      fieldComponent: YesNoSelect,
      label: 'Receives Steel Newsletter In PDF',
      width: 3
    }
  ],
  [
    ({
      name: 'address',
      emptyValue: '',
      component: InputField,
      label: 'Address',
      width: 4
    },
    {
      name: 'state_or_province',
      emptyValue: '',
      component: InputField,
      label: 'State or province',
      width: 3
    },
    {
      name: 'phone',
      emptyValue: '',
      component: InputField,
      label: 'Phone',
      width: 3
    },
    {
      name: 'fax',
      emptyValue: '',
      component: InputField,
      label: 'Fax',
      width: 5
    })
  ],
  [
    {
      name: 'city',
      emptyValue: '',
      component: InputField,
      label: 'City (town)',
      width: 4
    },
    {
      name: 'postcode',
      emptyValue: '',
      component: InputField,
      label: 'Postcode',
      width: 3
    },
    {
      name: 'mobile',
      emptyValue: '',
      component: InputField,
      label: 'Mobile',
      width: 3
    },
    {
      name: 'website',
      emptyValue: '',
      component: InputField,
      label: 'Website',
      width: 5
    }
  ],
  [
    {
      name: 'product',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: MarketDataReportRelatedProductSelect,
      label: 'Product',
      width: 5
    },
    {
      name: 'product_status',
      emptyValue: null,
      fieldComponent: ProductStatusSelect,
      label: 'Product status',
      width: 4
    }
  ],
  [
    {
      name: 'uses_mobile_app',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Uses mobile app',
      width: 2
    },
    {
      name: 'has_my_snapshot_prices',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has my snapshot prices',
      width: 3
    },
    {
      name: 'has_comments',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has Comments',
      width: 4
    },
    {
      name: 'has_feedback',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has Feedback',
      width: 4
    }
  ]
];
