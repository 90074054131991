import _ from 'lodash';
import React from 'react';

import { Field, SelectField } from 'components';

import { CLIENT_EVENT_STATUSES } from '../ClientEventStatus/constants';

const EventStatusSelect = ({ name, ...rest }) => {
  const options = _(CLIENT_EVENT_STATUSES)
    .values()
    .map(eventStatus => ({
      key: eventStatus,
      value: eventStatus,
      label: eventStatus
    }))
    .value();

  return (
    <Field
      name={name || 'status'}
      component={SelectField}
      options={options}
      isClearable
      {...rest}
    />
  );
};

export default EventStatusSelect;
