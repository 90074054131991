import React from 'react';
import _ from 'lodash';

import { Formik } from 'formik';

import { Form, Segment, Statistic } from 'semantic-ui-react';

import { buildInitialValuesFromFilters } from 'utils/filters';

import { FilterButtons, Flex } from 'components';

import {
  FilterRow,
  AdvancedFiltersLabel,
  ExcludeFiltersLabel
} from './components';

class Filters extends React.Component {
  state = {
    showAdvancedLayout: false,
    showExcludeLayout: false
  };

  toggleadvancedLayout = () => {
    this.setState(prevState => ({
      showAdvancedLayout: !prevState.showAdvancedLayout
    }));
  };

  toggleExcludeLayout = () => {
    this.setState(prevState => ({
      showExcludeLayout: !prevState.showExcludeLayout
    }));
  };

  submitSearch = values => {
    this.props.filterBy(values);
  };

  resetSearch = resetForm => {
    const { clearFilters } = this.props;

    resetForm();
    clearFilters();
  };

  render() {
    const {
      filters,
      layout,
      count,
      advancedLayout = null,
      additionalStatistics = '',
      excludeLayout = null,
      hasExcludedFilters = false,
      totalCount = null,
      excludedCount = null
    } = this.props;
    const withCount = 'count' in this.props;

    const { showAdvancedLayout, showExcludeLayout } = this.state;

    let filtersLayout = _.flatten(layout);

    if (advancedLayout) {
      const advancedFiltersLayout = _.flatten(advancedLayout);

      filtersLayout = _.concat(filtersLayout, advancedFiltersLayout);
    }

    if (excludeLayout) {
      const excludeFiltersLayout = _.flatten(excludeLayout);

      filtersLayout = _.concat(filtersLayout, excludeFiltersLayout);
    }

    const initialValues = buildInitialValuesFromFilters(filters, filtersLayout);

    return (
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.submitSearch}>
          {({ handleSubmit, resetForm, setFieldValue, values }) => (
            <Form>
              {layout.map((row, index) => (
                <FilterRow
                  key={index}
                  row={row}
                  formikBag={{ setFieldValue, values }}
                />
              ))}
              {advancedLayout && (
                <AdvancedFiltersLabel
                  isOpen={showAdvancedLayout}
                  toggleAction={this.toggleadvancedLayout}
                />
              )}
              {showAdvancedLayout && (
                <>
                  {advancedLayout.map(row => (
                    <FilterRow
                      row={row}
                      formikBag={{ setFieldValue, values }}
                    />
                  ))}
                  <AdvancedFiltersLabel
                    isOpen={showAdvancedLayout}
                    toggleAction={this.toggleadvancedLayout}
                  />
                </>
              )}
              {excludeLayout && (
                <ExcludeFiltersLabel
                  isOpen={showExcludeLayout}
                  toggleAction={this.toggleExcludeLayout}
                />
              )}
              {showExcludeLayout && (
                <>
                  <div
                    style={{
                      backgroundColor: '#fdb8c0',
                      padding: '15px',
                      margin: '10px 0'
                    }}>
                    {excludeLayout.map(row => (
                      <FilterRow
                        row={row}
                        formikBag={{ setFieldValue, values }}
                      />
                    ))}
                  </div>
                  <ExcludeFiltersLabel
                    isOpen={showExcludeLayout}
                    toggleAction={this.toggleExcludeLayout}
                  />
                </>
              )}
              <Flex spaceBetween>
                <Flex>
                  {withCount && (
                    <Statistic.Group size="tiny">
                      <Statistic color="green">
                        <Statistic.Value>{count}</Statistic.Value>
                        <Statistic.Label>Results</Statistic.Label>
                      </Statistic>
                      {hasExcludedFilters && (
                        <div>
                          <Statistic color="blue">
                            <Statistic.Value>{totalCount}</Statistic.Value>
                            <Statistic.Label>total</Statistic.Label>
                          </Statistic>
                          <Statistic color="red">
                            <Statistic.Value>{excludedCount}</Statistic.Value>
                            <Statistic.Label>excluded</Statistic.Label>
                          </Statistic>
                        </div>
                      )}
                    </Statistic.Group>
                  )}
                  {additionalStatistics}
                </Flex>

                <FilterButtons
                  searchAction={handleSubmit}
                  resetAction={() => this.resetSearch(resetForm)}
                />
              </Flex>
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default Filters;
