import React from 'react';

import { Button, Header, Modal } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { pageUrl } from 'config/routes';
import { PriceSeriesUpdate } from 'pages/PriceSeries';
import { PMPriceSeriesUpdate } from 'pages';

class ArticlePricesUpdateModal extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleSubmit = async () => {
    const { article, caller } = this.props;
    let redirectPage = {
      article: PriceSeriesUpdate,
      'pm-article': PMPriceSeriesUpdate
    };

    const searchParams = {
      category: article.price_categories,
      product: article.price_products,
      region: article.price_series_regions
    };
    this.props.history.push({
      pathname: pageUrl(redirectPage[caller]),
      search: queryString.stringify(searchParams)
    });
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.handleClose}
        basic
        size="small">
        <Header icon="cloud upload" content="Update related Price Series" />
        <Modal.Content>
          <p>
            Do you want to update Price Series related to article regions, price
            categories and price products ?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="grey" inverted onClick={this.handleClose}>
            No
          </Button>
          <Button color="green" inverted onClick={this.handleSubmit}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(ArticlePricesUpdateModal);
