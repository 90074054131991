export const ARTICLE_STATUSES = [
  'Work In Progress',
  'Ready for Editing',
  'Editing in Progress',
  'Ready for Publication',
  'Live'
];

export const PRICE_SERIES_FORMATS = ['Single Price', 'Range'];
export const PRICE_SERIES_FREQUENCIES = [
  'Daily',
  'Weekly',
  'Monthly',
  'Quarterly',
  'Yearly',
  'Biannual'
];
export const PRICE_SERIES_SCALES = ['1', '100', '1000'];
export const PRICE_SERIES_SOURCES = [
  'Manual',
  'Trading Economics',
  'London Metal Exchange',
  'Shanghai Metals',
  'Kallindex'
];
export const EMAIL_TYPES = ['Plain Text', 'HTML'];
export const PROMO_CODES_DISCOUNT_TYPES = ['Amount', '%'];
export const WEEKLY_SEASM_TAGS = [
  'Trade Report',
  'Production Report',
  'Markets Report'
];
export const CONTENT_SECTION_TYPE = ['Standard', 'Prices', 'Articles'];
export const CONTENT_SECTION_COLUMN = {
  Left: '1',
  Center: '2',
  Right: '3'
};
export const ARTICLE_GROUPING = ['Region', 'Group', 'Unknown'];
export const ALLOWED_FEEDBACK_PAGES = ['Home', 'Login:', 'Subscription:'];
export const FEEDBACK_DISPLAY_OPTIONS = ['Yes', 'Yes, but anonymously', 'No'];
export const PRICE_SERIES_OPEN_ACTION_TYPES = ['Open', 'Download'];
