import _ from 'lodash';

import React from 'react';

import {
  Modal as SemanticModal,
  Icon,
  Header,
  Button
} from 'semantic-ui-react';

import { Flex } from 'components';

class Modal extends React.Component {
  close = () => {
    this.props.onClose();
  };

  render() {
    const { children, header, additionalActions, ...rest } = this.props;

    return (
      <SemanticModal open={true} size="large" centered={false} {...rest}>
        <SemanticModal.Header>
          <Flex spaceBetween>
            <Header as="h2" style={{ margin: 0 }} data-testid="modal-header">
              {header}
            </Header>
            <Icon
              name="close"
              onClick={this.close}
              data-testid="modal-close-icon-btn"
            />
          </Flex>
        </SemanticModal.Header>
        <SemanticModal.Content>{children}</SemanticModal.Content>
        <SemanticModal.Actions>
          <Button
            color="red"
            onClick={this.close}
            data-testid="modal-close-btn">
            Close
          </Button>
          {!_.isEmpty(additionalActions) ? additionalActions : null}
        </SemanticModal.Actions>
      </SemanticModal>
    );
  }
}

export default Modal;
