import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import { Icon } from 'semantic-ui-react';

import { FilePreview, ExternalLinkButton, Flex, YesNo } from 'components';
import { formatDate } from 'utils';

import { pageUrl } from 'config/routes';

import { ClientList, InvoiceList } from 'pages';
import { PERMISSIONS } from 'permissions';

import PresalePrices from './PresalePrices';
import TotalPaidSum from './TotalPaidSum';
import TotalRow from './TotalRow';

import { downloadCheckInCsvUrl, downloadAttendeeListCsvUrl } from './sdk';

import {
  EVENT_STATUSES,
  SALES_EVENT_STATUSES,
  INVOICE_STATUSES
} from './constants';
import { CLIENT_EVENT_STATUSES } from 'components/fields/ClientEventStatus/constants';

const calculateEventDates = event => {
  if (!event.duration || (event.duration && event.duration === 1)) {
    return formatDate(event.date_start);
  }
  const durationType = _.get(event, 'duration_type', 'days');

  const eventStart = moment(event.date_start);
  const eventEnd = moment(event.date_start).add(
    event.duration - 1,
    durationType
  );

  if (durationType !== 'days') {
    if (eventStart.isSame(eventEnd, 'day')) {
      return formatDate(eventStart);
    }
  }

  return `${formatDate(eventStart)} - ${formatDate(eventEnd)}`;
};

const showFile = fileId => (fileId ? <FilePreview fileId={fileId} /> : 'N/A');

const calculateClientItemsForStatus = (items, status) =>
  _.filter(items, item => item.status === status).length;

const calculatePeopleNumbers = clientItems => ({
  ..._.mapValues(EVENT_STATUSES, value =>
    calculateClientItemsForStatus(clientItems, value)
  )
});

const calculateSalesEventActivity = clientItems => ({
  ..._.mapValues(SALES_EVENT_STATUSES, value =>
    calculateClientItemsForStatus(clientItems, value)
  )
});

// objects = {paid: 1, registered: 2, ...}
// with map get -> [1,2, ...]
const calculateTotal = objects => _.sum(_.map(objects, (value, k) => value));

const buildEventIndex = events => {
  const eventIndex = {};

  _.forEach(events, event => {
    let peopleNumbers = calculatePeopleNumbers(event.client_items);

    let salesNumbers = calculateSalesEventActivity(event.client_items);

    eventIndex[event.id] = {
      total: calculateTotal(peopleNumbers),
      ...peopleNumbers,
      ...salesNumbers
    };
  });

  return eventIndex;
};

const buildClientsUrl = (event, status) =>
  `${pageUrl(ClientList)}?upcoming_event=${event.id}&event_status=${status}`;

const buildInvoiceUrl = (event, eventStatuses, invoiceStatuses) => {
  const invoiceStatusesArray = Array.isArray(invoiceStatuses)
    ? invoiceStatuses
    : [invoiceStatuses];
  const invoiceStatusesQueryParams = invoiceStatusesArray
    .map(status => `invoice_status=${status}`)
    .join('&');

  const eventStatusArray = Array.isArray(eventStatuses)
    ? eventStatuses
    : [eventStatuses];
  const eventStatusQueryParams = eventStatusArray
    .map(status => `client_event_status=${status}`)
    .join('&');

  return `${pageUrl(
    InvoiceList
  )}?${invoiceStatusesQueryParams}&upcoming_event=${
    event.id
  }&${eventStatusQueryParams}`;
};

const rowsTemplates = [
  {
    label: 'Dates',
    dataFunction: event => calculateEventDates(event)
  },
  {
    label: 'City',
    dataFunction: event => event.city
  },
  {
    label: 'Country',
    dataFunction: event => _.get(event, 'country.title', '')
  },
  {
    label: 'Presale Prices',
    dataFunction: event => <PresalePrices event={event} />
  },
  {
    label: 'Is Free',
    dataFunction: event => (
      <YesNo value={event.is_free} style={{ justifyContent: 'flex-start' }} />
    )
  },
  {
    label: 'Is Registration Open',
    dataFunction: event => (
      <YesNo
        value={
          (event.is_free && event.is_registration_open) ||
          (!event.is_free &&
            event.is_registration_open &&
            !_.isEmpty(event.presales))
        }
        style={{ justifyContent: 'flex-start' }}
      />
    )
  },
  {
    label: 'Paid',
    dataFunction: (event, eventIndex) => (
      <TotalPaidSum
        eventIndexValue={eventIndex[event.id].paid}
        eventPaidSum={event.paid_delegates_sum}
        link={buildClientsUrl(event, EVENT_STATUSES.paid)}
        linkTotal={buildInvoiceUrl(
          event,
          CLIENT_EVENT_STATUSES.paidDelegate,
          INVOICE_STATUSES.paid
        )}
        totalLabel="Total paid"
      />
    )
  },
  {
    label: 'Paid Virtual',
    dataFunction: (event, eventIndex) => eventIndex[event.id].paidVirtual,
    linkTo: event => buildClientsUrl(event, EVENT_STATUSES.paidVirtual)
  },
  {
    label: 'Registered',
    dataFunction: (event, eventIndex) => (
      <TotalPaidSum
        eventIndexValue={eventIndex[event.id].registered}
        eventPaidSum={event.registered_sum}
        link={buildClientsUrl(event, EVENT_STATUSES.registered)}
        linkTotal={buildInvoiceUrl(event, CLIENT_EVENT_STATUSES.registered, [
          INVOICE_STATUSES.notPaid,
          INVOICE_STATUSES.paymentPromised,
          INVOICE_STATUSES.unknown
        ])}
        totalLabel="Total"
      />
    )
  },
  {
    label: 'Speakers',
    dataFunction: (event, eventIndex) => eventIndex[event.id].speaker,
    linkTo: event => buildClientsUrl(event, EVENT_STATUSES.speaker)
  },
  {
    label: 'Special Guests',
    dataFunction: (event, eventIndex) => eventIndex[event.id].specialGuests,
    linkTo: event => buildClientsUrl(event, EVENT_STATUSES.specialGuests)
  },
  {
    label: 'Special Guests Virtual',
    dataFunction: (event, eventIndex) =>
      eventIndex[event.id].specialGuestsVirtual,
    linkTo: event => buildClientsUrl(event, EVENT_STATUSES.specialGuestsVirtual)
  },
  {
    label: 'Sponsor',
    dataFunction: (event, eventIndex) => (
      <TotalPaidSum
        eventIndexValue={eventIndex[event.id].sponsor}
        eventPaidSum={event.sponsor_sum}
        link={buildClientsUrl(event, EVENT_STATUSES.sponsor)}
        linkTotal={buildInvoiceUrl(
          event,
          CLIENT_EVENT_STATUSES.sponsor,
          INVOICE_STATUSES.paid
        )}
        totalLabel={'Total paid'}
      />
    )
  },
  {
    label: 'Sponsor - Interested',
    dataFunction: (event, eventIndex) => eventIndex[event.id].sponsorInterested,
    linkTo: event => buildClientsUrl(event, EVENT_STATUSES.sponsorInterested)
  },
  {
    label: 'Sponsor - Not Attending',
    dataFunction: (event, eventIndex) =>
      eventIndex[event.id].sponsorNotAttending,
    linkTo: event => buildClientsUrl(event, EVENT_STATUSES.sponsorNotAttending)
  },
  {
    label: 'Press',
    dataFunction: (event, eventIndex) => eventIndex[event.id].press,
    linkTo: event => buildClientsUrl(event, EVENT_STATUSES.press)
  },
  {
    label: 'Staff',
    dataFunction: (event, eventIndex) => eventIndex[event.id].staff,
    linkTo: event => buildClientsUrl(event, EVENT_STATUSES.staff)
  },
  {
    label: 'Total',
    dataFunction: (event, eventIndex) => (
      <TotalRow
        eventIndexValue={eventIndex[event.id].total}
        eventPaidSum={event.paid_delegates_sum + event.sponsor_sum}
        linkTotal={buildInvoiceUrl(
          event,
          [CLIENT_EVENT_STATUSES.paidDelegate, CLIENT_EVENT_STATUSES.sponsor],
          INVOICE_STATUSES.paid
        )}
        outstandingSum={event.registered_sum}
        linkOutstanding={buildInvoiceUrl(
          event,
          [CLIENT_EVENT_STATUSES.registered],
          [
            INVOICE_STATUSES.notPaid,
            INVOICE_STATUSES.paymentPromised,
            INVOICE_STATUSES.unknown
          ]
        )}
        linkGrandTotal={buildInvoiceUrl(
          event,
          [
            CLIENT_EVENT_STATUSES.paidDelegate,
            CLIENT_EVENT_STATUSES.sponsor,
            CLIENT_EVENT_STATUSES.registered
          ],
          [
            INVOICE_STATUSES.paid,
            INVOICE_STATUSES.notPaid,
            INVOICE_STATUSES.paymentPromised,
            INVOICE_STATUSES.unknown
          ]
        )}
      />
    ),
    props: { style: { backgroundColor: 'rgba(33, 186, 69, 0.23)' } }
  },
  {
    label: 'Pending Registration',
    dataFunction: (event, eventIndex) =>
      eventIndex[event.id].pendingRegistration,
    linkTo: event =>
      buildClientsUrl(event, SALES_EVENT_STATUSES.pendingRegistration),
    props: { style: { backgroundColor: 'rgba(251, 189, 8, 0.22)' } }
  },
  {
    label: 'Interested',
    dataFunction: (event, eventIndex) => eventIndex[event.id].interested,
    linkTo: event => buildClientsUrl(event, SALES_EVENT_STATUSES.interested)
  },
  {
    label: 'Contacted',
    dataFunction: (event, eventIndex) => eventIndex[event.id].contacted,
    linkTo: event => buildClientsUrl(event, SALES_EVENT_STATUSES.contacted)
  },
  {
    label: "Can't make it",
    dataFunction: (event, eventIndex) => eventIndex[event.id].cantMakeIt,
    linkTo: event => buildClientsUrl(event, SALES_EVENT_STATUSES.cantMakeIt)
  },
  {
    label: 'Not interested',
    dataFunction: (event, eventIndex) => eventIndex[event.id].notInterested,
    linkTo: event => buildClientsUrl(event, SALES_EVENT_STATUSES.notInterested)
  },
  {
    label: 'Application File',
    dataFunction: event => showFile(event.application_file)
  },
  {
    label: 'Sponsorship',
    dataFunction: event => showFile(event.sponsorship)
  },
  {
    label: 'Hotel Booking Form',
    dataFunction: event => showFile(event.hotel_booking_form)
  },
  {
    label: 'Actions',
    dataFunction: event => (
      <Flex>
        <ExternalLinkButton
          style={{ paddingLeft: '0.7em', paddingRight: '0.7em' }}
          url={downloadCheckInCsvUrl(event.id)}>
          <Icon name="download" />
          Check-in
        </ExternalLinkButton>
        <ExternalLinkButton
          style={{ paddingLeft: '0.7em', paddingRight: '0.7em' }}
          url={downloadAttendeeListCsvUrl(event.id)}
          color="green">
          <Icon name="download" />
          Attendees
        </ExternalLinkButton>
      </Flex>
    ),
    props: {
      textAlign: 'center',
      permissions: [PERMISSIONS.VIEW_EVENTS_EXPORTS]
    }
  }
];

export const buildEventRows = events => {
  const eventIndex = buildEventIndex(events);
  const data = [];

  _.forEach(rowsTemplates, rowTemplate => {
    const row = [
      { render: rowTemplate.label, props: rowTemplate.props },
      ...events.map(event => ({
        render: rowTemplate.dataFunction(event, eventIndex),
        linkTo: rowTemplate.linkTo ? rowTemplate.linkTo(event) : null,
        props: rowTemplate.props
      }))
    ];

    data.push(row);
  });

  return data;
};
