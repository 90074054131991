export const CALENDARS = [
  {
    title: 'Holiday Calendar',
    url:
      'https://calendar.google.com/calendar/embed?title=Kallanish%20Group%20Calendar&height=600&wkst=2&bgcolor=%2399ff99&src=kallanish.com_pf2veatji3v3k8n6gh701kqogs%40group.calendar.google.com&color=%2323164E&ctz=Europe%2FLondon'
  },
  {
    title: 'Industry Calendar',
    url:
      'https://calendar.google.com/calendar/embed?title=Kallanish%20Events%20Calendar&height=600&wkst=2&bgcolor=%2399ff99&src=c_bacdf1034324eee58da9157e5a8e2062a1b70c8f92d37620b769a85f17e5c90e%40group.calendar.google.com&ctz=Europe%2FLondon'
  }
];

export const KALLANISH_VISION =
  'To be the preferred market intelligence resource for the global steel and energy industries.';

export const KALLANISH_MISSION =
  'To be recognised as authoritative and insightful, offering timely and relevant news and information to commodities professionals.';

export const KALLANISH_VALUES = [
  'Market-led',
  'High quality products and services',
  'Trusted resource',
  'Dynamic and forward thinking',
  'Independent'
];
