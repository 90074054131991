import _ from 'lodash';

import React from 'react';

import { Link } from 'react-router-dom';

import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { InvoiceDetail } from 'pages';

import { formatDate, openInNewTab } from 'utils';
import { roundWithPrecision } from 'utils/numbers';

import {
  TableFooter,
  SortableHeader,
  LinkButton,
  InvoiceSendModal
} from 'components';

import { BACKEND_URL } from 'sdk/urls';

const INVOICE_PREVIEW_URL = invoiceId =>
  `${BACKEND_URL}/crm/invoice_preview/${invoiceId}/`;

class InvoiceTable extends React.Component {
  state = {
    invoiceSendModal: false,
    selectedInvoice: null
  };

  openInvoiceSendModal = invoice => {
    this.setState({ invoiceSendModal: true, selectedInvoice: invoice });
  };

  closeInvoiceSendModal = () => {
    this.setState({ invoiceSendModal: false, selectedInvoice: null });
  };

  render() {
    const { invoices, sort, orderBy, pagination, clientId } = this.props;

    const { invoiceSendModal, selectedInvoice } = this.state;
    const colSpan = clientId ? 13 : 11;

    return (
      <>
        <Table celled selectable compact={true} size="large">
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colSpan={colSpan}>
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row txtAlign="center" verticalAlign="middle">
              <SortableHeader sort={sort} orderBy={orderBy} field="identifier">
                Identifier
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="proform_id">
                Proform ID
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="date">
                Date
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="paid_date">
                Date Paid
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="client_id">
                Client ID
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="name">
                Name
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="company">
                Company
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="email">
                Email
              </SortableHeader>
              <SortableHeader
                sort={sort}
                orderBy={orderBy}
                field="invoice_status">
                Invoice status
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="total">
                Total
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="currency">
                Currency
              </SortableHeader>
              {clientId && (
                <>
                  <Table.HeaderCell>Preview</Table.HeaderCell>
                  <Table.HeaderCell>Send</Table.HeaderCell>
                </>
              )}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {invoices.map(invoice => (
              <Table.Row key={invoice.id}>
                <Table.Cell>
                  <Link to={pageUrl(InvoiceDetail, { invoiceId: invoice.id })}>
                    {invoice.identifier}
                  </Link>
                </Table.Cell>
                <Table.Cell>{invoice.proform_id}</Table.Cell>
                <Table.Cell>
                  {invoice.invoice_date && formatDate(invoice.invoice_date)}
                </Table.Cell>
                <Table.Cell>
                  {invoice.invoice_paid_on &&
                    formatDate(invoice.invoice_paid_on)}
                </Table.Cell>
                <Table.Cell>
                  {_.get(invoice.contact_info, 'client.id')}
                </Table.Cell>
                <Table.Cell>{_.get(invoice.contact_info, 'name')}</Table.Cell>
                <Table.Cell>
                  {_.get(invoice.contact_info, 'company_name')}
                </Table.Cell>
                <Table.Cell>{_.get(invoice.contact_info, 'email')}</Table.Cell>
                <Table.Cell>{invoice.invoice_status}</Table.Cell>
                <Table.Cell>
                  {roundWithPrecision(invoice.payment_info.total_price)}
                </Table.Cell>
                <Table.Cell>{invoice.payment_info.currency}</Table.Cell>
                {clientId && (
                  <>
                    <Table.Cell>
                      <LinkButton
                        onClick={() =>
                          openInNewTab(INVOICE_PREVIEW_URL(invoice.id))
                        }>
                        Preview
                      </LinkButton>
                    </Table.Cell>

                    <Table.Cell>
                      <LinkButton
                        onClick={() => this.openInvoiceSendModal(invoice)}>
                        Send
                      </LinkButton>
                    </Table.Cell>
                  </>
                )}
              </Table.Row>
            ))}
          </Table.Body>
          <TableFooter>{pagination}</TableFooter>
        </Table>
        {invoiceSendModal && (
          <InvoiceSendModal
            invoice={selectedInvoice}
            onClose={this.closeInvoiceSendModal}
          />
        )}
      </>
    );
  }
}

export default InvoiceTable;
