import React from 'react';

import { Link } from 'react-router-dom';

import _ from 'lodash';

import { Segment, Header } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { ClientCreateCorporateChild } from 'pages';

import { Button, ExternalLinkButton, Flex } from 'components';

import { CorporateMasterAccountTable } from './components';
import { downloadCAccountsInfoUrl } from './sdk';

class CorporateMasterClientProfileForm extends React.Component {
  render() {
    const { client, fetchClient } = this.props;
    const corporateProfilesAttached = !_.isEmpty(client.corporate_profiles);
    const corporateProfiles = client.corporate_profiles;

    return (
      <Segment>
        <Flex spaceBetween>
          <Header as="h2" data-testid="linked-c-accounts-header">
            Linked C Accounts:{' '}
            {corporateProfilesAttached && (
              <span>Total Accounts: {client.corporate_profiles.length}</span>
            )}
          </Header>
          <ExternalLinkButton
            url={downloadCAccountsInfoUrl(client.id)}
            data-testid="linked-c-accounts-download-btn">
            Download
          </ExternalLinkButton>
        </Flex>

        {!corporateProfilesAttached && <p>There are no attached C accounts.</p>}

        {corporateProfilesAttached && (
          <CorporateMasterAccountTable
            corporateProfiles={corporateProfiles}
            client={client}
            fetchClient={fetchClient}
          />
        )}
        {_.get(client, 'client_profile.is_corporate_master_profile') && (
          <Link
            to={pageUrl(ClientCreateCorporateChild, {
              corporateMasterId: client.id
            })}>
            <Button color="green" data-testid="linked-c-accounts-add-btn">
              Add New C Account
            </Button>
          </Link>
        )}
      </Segment>
    );
  }
}

export default CorporateMasterClientProfileForm;
