import React from 'react';

import _ from 'lodash';
import cx from 'classnames';

import { Error } from 'components';

import styles from './styles.module.css';

const Input = ({
  ref,
  label,
  className,
  form,
  field,
  onChange = () => {},
  withoutErrorMessage = false,
  withoutLabel = false,
  small = false,
  ...props
}) => {
  const hasErrors =
    _.get(form.touched, field.name) && _.get(form.errors, field.name);

  return (
    <div className={cx(styles.container, className, { [styles.small]: small })}>
      {!withoutLabel && label && <label>{label}</label>}
      <input
        ref={ref}
        {...field}
        {...props}
        onChange={event => {
          field.onChange(event);
          onChange(event, event.target.value);
        }}
        className={cx(styles.customInput, {
          [styles.hasErrors]: hasErrors
        })}
      />
      {!withoutErrorMessage && hasErrors && (
        <Error className={styles.error} data-test="input-field-error">
          {_.get(form.errors, field.name)}
        </Error>
      )}
    </div>
  );
};

export default Input;
