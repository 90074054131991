import _ from 'lodash';
import React, { useState } from 'react';

import {
  Header,
  Segment,
  Accordion,
  AccordionContent,
  AccordionTitle,
  Icon,
  List,
  ListItem
} from 'semantic-ui-react';

import { Page, LoginRequired, Flex } from 'components';
import { slugified } from 'utils';

import { buildCalendarElement } from './utils';
import {
  CALENDARS,
  KALLANISH_VISION,
  KALLANISH_MISSION,
  KALLANISH_VALUES
} from './constants';
import EasyAccessFiles from './EasyAccessFiles';
import styles from './styles.module.css';

const ACCORDION_SECTIONS = [
  ...CALENDARS.map(({ title, url }) => ({
    title,
    content: <div dangerouslySetInnerHTML={buildCalendarElement(url)} />
  })),
  {
    title: 'Easy Access Files',
    content: <EasyAccessFiles />
  }
];

const Dashboard = ({ user }) => {
  // Neither section is shown by default
  const [activeIndexes, setActiveIndexes] = useState([]);

  const handleAccordionClick = index =>
    setActiveIndexes(_.xor(activeIndexes, [index]));

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content maxWidth>
          <Flex>
            <div className={styles.contentWrapper}>
              <Flex flexStart style={{ flexDirection: 'column', gap: '10px' }}>
                {_.map(ACCORDION_SECTIONS, ({ title, content }, index) => {
                  const active = activeIndexes.includes(index);

                  return (
                    <Accordion
                      key={index}
                      fluid
                      styled
                      data-testid={slugified(title)}>
                      <AccordionTitle
                        active={active}
                        onClick={() => handleAccordionClick(index)}>
                        <Icon name="dropdown" />
                        {title}
                      </AccordionTitle>
                      <AccordionContent active={active}>
                        {content}
                      </AccordionContent>
                    </Accordion>
                  );
                })}
              </Flex>

              <Segment className={styles.valuesWrapper}>
                <Header as="h1" textAlign="center">
                  Knowledge Matters
                </Header>
                <div className={styles.valuesContent}>
                  <Segment vertical>
                    <Header as="h3">Our Vision</Header>
                    {KALLANISH_VISION}
                  </Segment>
                  <Segment vertical>
                    <Header as="h3">Our Mission</Header>
                    {KALLANISH_MISSION}
                  </Segment>
                  <Segment vertical>
                    <Header as="h3">Values</Header>
                    <List bulleted>
                      {KALLANISH_VALUES.map(value => (
                        <ListItem key={value}>{value}</ListItem>
                      ))}
                    </List>
                  </Segment>
                </div>
              </Segment>
            </div>
          </Flex>
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default LoginRequired(Dashboard);
