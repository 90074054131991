import React from 'react';

import cx from 'classnames';

import styles from './styles.module.css';

const ArticleStatusOptions = {
  WORK_IN_PROGRESS: 'Work In Progress',
  READY_FOR_EDITING: 'Ready for Editing',
  EDITING_IN_PROGRESS: 'Editing in Progress',
  READY_FOR_PUBLICATION: 'Ready for Publication',
  LIVE: 'Live'
};

const STATUS_TO_CLASS = {
  [ArticleStatusOptions.WORK_IN_PROGRESS]: styles['work-in-progress'],
  [ArticleStatusOptions.READY_FOR_EDITING]: styles['ready-for-editing'],
  [ArticleStatusOptions.EDITING_IN_PROGRESS]: styles['editing-in-progress'],
  [ArticleStatusOptions.READY_FOR_PUBLICATION]: styles['ready-for-publication'],
  [ArticleStatusOptions.LIVE]: styles.live
};

const ArticleStatus = ({ value }) => (
  <span className={cx(styles['article-status'], STATUS_TO_CLASS[value])}>
    {value}
  </span>
);

export default ArticleStatus;
