import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';
import { stringifyParams } from 'utils';

export const trackedPriceSeriesUserAggregation = async params => {
  const url = `${BASE_URL}/prices/series/v2/tracked-price-series/user-aggregation/`;

  return await requestSdk(() => get(url, params));
};

export const downloadTrackedPriceSeriesUserAggregationUrl = params =>
  `${BASE_URL}/prices/series/v2/tracked-price-series/user-aggregation/export/?${stringifyParams(
    params
  )}`;
