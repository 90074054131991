export function buildCalendarElement(url) {
  return {
    __html: `<iframe
              src="${url}"
              style="border:solid 1px #777"
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"></iframe>`
  };
}
