import { useEffect, useState, useMemo } from 'react';
import { Table, Message, Icon, Dimmer } from 'semantic-ui-react';

import { ClientDetail } from 'pages';
import { pageUrl } from 'config/routes';
import { Modal, SortableHeader, Loader, ExternalLinkButton } from 'components';

import { buildFilters } from 'utils/filters';
import { notifyErrors } from 'utils/notifications';
import { useSortable, DESCENDING, buildSort } from 'utils/sort';

import {
  trackedPriceSeriesUserAggregation,
  downloadTrackedPriceSeriesUserAggregationUrl
} from './sdk';
import styles from './styles.module.css';

const UserAggregationModal = ({ filters, onClose }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [trackingData, setTrackingData] = useState([]);

  const { data: sortedData, sort, handleSortUpdate } = useSortable({
    defaultData: trackingData,
    defaultField: 'total_actions',
    defaultDirection: DESCENDING
  });

  useEffect(() => {
    async function fetchData() {
      const { data, success, errors } = await trackedPriceSeriesUserAggregation(
        buildFilters(filters)
      );

      if (success) {
        setTrackingData(data);
      } else {
        notifyErrors(errors);
      }

      setIsFetching(false);
    }

    fetchData();
  }, [filters]);

  const filtersForExport = useMemo(
    () => ({
      ...filters,
      ...buildSort(sort)
    }),
    [filters, sort]
  );

  return (
    <Modal
      header="User Aggregation"
      onClose={onClose}
      additionalActions={
        <ExternalLinkButton
          url={downloadTrackedPriceSeriesUserAggregationUrl(filtersForExport)}>
          <Icon name="download" />
          Export
        </ExternalLinkButton>
      }>
      <Message color="blue">
        <Icon name="info circle" />
        Filters from list view are also applied here.
      </Message>

      {isFetching ? (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      ) : (
        <div className={styles.TableScroll}>
          <Table celled compact>
            <Table.Header style={{ position: 'sticky', zIndex: 1, top: '0' }}>
              <Table.Row>
                <Table.HeaderCell>Client ID</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="total_opens">
                  # of Opens
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="total_downloads">
                  # of Downloads
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="total_actions">
                  Total Actions
                </SortableHeader>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {sortedData.map(item => (
                <Table.Row key={item.user_id}>
                  <Table.Cell>
                    <a href={pageUrl(ClientDetail, { clientId: item.user_id })}>
                      {item.user_id}
                    </a>
                  </Table.Cell>
                  <Table.Cell>{item.user_email}</Table.Cell>
                  <Table.Cell>{item.total_opens}</Table.Cell>
                  <Table.Cell>{item.total_downloads}</Table.Cell>
                  <Table.Cell>{item.total_actions}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </Modal>
  );
};

export default UserAggregationModal;
