import _ from 'lodash';
import moment from 'moment';
import { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListIcon,
  ListContent,
  ListHeader,
  ListDescription
} from 'semantic-ui-react';

import { ExternalLink } from 'components';
import { notifyErrors } from 'utils/notifications';

import { easyAccessFilesList } from './sdk';

const EasyAccessFiles = () => {
  const [easyAccessFiles, setEasyAccessFiles] = useState([]);

  useEffect(() => {
    const fetchEasyAccessFiles = async () => {
      const { data, errors, success } = await easyAccessFilesList();

      if (success) {
        setEasyAccessFiles(data);
      } else {
        notifyErrors(errors);
      }
    };

    fetchEasyAccessFiles();
  }, []);

  return (
    <List divided relaxed>
      {_.isEmpty(easyAccessFiles) && 'No easy access files found.'}
      {!_.isEmpty(easyAccessFiles) &&
        easyAccessFiles.map(({ filename, url, modified_at }) => (
          <ListItem key={url}>
            <ListIcon name="file" size="large" verticalAlign="middle" />
            <ListContent>
              <ListHeader>
                <ExternalLink url={url}>{filename}</ExternalLink>
              </ListHeader>
              <ListDescription>
                Last Modified {moment(modified_at).fromNow()}
              </ListDescription>
            </ListContent>
          </ListItem>
        ))}
    </List>
  );
};

export default EasyAccessFiles;
