import { transformValuesForMultiselect } from 'utils/filters';

import {
  InputField,
  DateField,
  UniSelect,
  AdminSelect,
  SubscriptionStatusSelect
} from 'components';

export const FILTERS = [
  [
    {
      name: 'open_from',
      emptyValue: null,
      component: DateField,
      label: 'Opened From'
    },
    {
      name: 'open_to',
      emptyValue: null,
      component: DateField,
      label: 'Opened to'
    },
    {
      name: 'action_type',
      emptyValue: null,
      component: UniSelect,
      label: 'Access Method',
      props: () => ({
        source: 'trackedPriceSeries'
      })
    }
  ],
  [
    {
      name: 'prices',
      emptyValue: null,
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'Price Serie',
      props: () => ({
        source: 'priceSeriesAll',
        isMulti: true
      })
    },
    {
      name: 'price_product_group',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'Product Group',
      props: () => ({
        source: 'priceSerieProductGroups'
      })
    }
  ],
  [
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account Manager'
    },
    {
      name: 'subscription_status',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription status',
      props: () => ({
        isMulti: true
      })
    }
  ],
  [
    {
      name: 'user',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      type: 'number'
    },
    {
      name: 'email',
      emptyValue: '',
      component: InputField,
      label: 'Email address'
    },
    {
      name: 'first_name',
      emptyValue: '',
      component: InputField,
      label: 'First name'
    },
    {
      name: 'last_name',
      emptyValue: '',
      component: InputField,
      label: 'Last name'
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name'
    }
  ]
];
